import React from 'react'
import { Link } from 'gatsby'
import styled, { x, css } from '@xstyled/styled-components'
import { breakpoints, th } from '@xstyled/system'

import { Container, Row, Col } from '@atoms/Grid'
import { Text, H3 } from '@atoms/Typography'
import Divider from '@atoms/Divider'
import BackButton from '@atoms/BackButton'
import HTMLContent from '@atoms/HTMLContent'
import transformMDtoHTML from '@utils/transformMDtoHTML'

const CareerPage = ({ title, location, htmlIntroduction, htmlBody }) => {
  return (
    <Container>
      <Row>
        <Col col={{ xs: 1, xl: 1 / 2 }}>
          <Row>
            <Col>
              <x.div mb={{ lg: 50 }}>
                <BackButton
                  to="/careers"
                  variant={'dark'}
                  linkButton=" All jobs"
                />
              </x.div>
            </Col>
          </Row>
          <Row>
            <Col>
              <H3 as="h1">{title || ''}</H3>
              <S.TextLocation as="h2">{location || ''}</S.TextLocation>
            </Col>
          </Row>
        </Col>
        <Col col={{ xs: 1, xl: 1 / 2 }}>
          <S.Description
            dangerouslySetInnerHTML={{
              __html: htmlBody,
            }}
          />
          {/* 

            <Divider />

            <S.Footer
              dangerouslySetInnerHTML={{
                __html: htmlBody,
              }}
            /> */}
        </Col>
      </Row>
    </Container>
  )
}

const S = {}

S.Wrapper = styled.sectionBox`
  ${breakpoints({
    xs: css`
      margin-bottom: 18;
    `,
  })}
`

S.Back = styled(Link)`
  display: flex;
  align-items: center;
  font-weight: ${th('fontWeights.bold')};
  align-items: center;
  cursor: pointer;
  ${breakpoints({
    xs: css`
      margin-bottom: 40;
      width: 100%;
      font-size: 14;
    `,
    md: css`
      margin-bottom: 38;
      width: 200;
      font-size: 17;
    `,
  })}
`

S.TextLocation = styled(Text)`
  line-height: 1;
  margin-bottom: 30;
  ${breakpoints({
    xs: css`
      font-size: 14;
    `,
    md: css`
      font-size: 19;
    `,
  })}
`

S.Description = styled(HTMLContent)`
  ${breakpoints({
    xs: css`
      padding-bottom: 20;
      h3:first-of-type {
        margin-top: 0;
      }
      h3 {
        margin-top: 25;
        margin-bottom: 25;
      }
    `,
    xl: css`
      h3 {
        margin-top: 30;
        margin-bottom: 30;
      }
    `,
  })}
`

S.Footer = styled(HTMLContent)`
  ${breakpoints({
    xs: css`
      padding-top: 20;
    `,
    md: css`
      padding-top: 0;
    `,
  })}
`

export default CareerPage
