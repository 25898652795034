import React from 'react'
import { graphql } from 'gatsby'
import styled, { css } from '@xstyled/styled-components'
import { breakpoints, th } from '@xstyled/system'

import Layout from '@organisms/Layout'
import CareerPage from '@organisms/CareerSingle/CareerPage'

const CareerSingle = ({ data }) => {
  const { meta, title, location } = data.career.frontmatter
  const { html: htmlBody } = data.career

  return (
    <Layout
      hamburgerMenu
      title={meta.title || ''}
      description={meta.description || ''}
      invertColor
    >
      <S.Main>
        <CareerPage title={title} location={location} htmlBody={htmlBody} />
      </S.Main>
    </Layout>
  )
}

const S = {}

S.Main = styled.mainBox`
  background: ${th('colors.dark')};
  color: ${th('colors.light')};

  ${breakpoints({
    xs: css`
      padding-top: 151;
      padding-bottom: 100;
    `,
    md: css`
      padding-top: 187;
      padding-bottom: 130;
    `,
  })}
`

export default CareerSingle

export const pageQuery = graphql`
  query CareerQuery($slug: String!) {
    career: markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        date_published: date(formatString: "YYYY-MM-DD")
        date_modifed: date(formatString: "YYYY-MM-DD")
        slug
        location
        meta {
          description
          title
          image {
            childImageSharp {
              gatsbyImageData(width: 500, layout: CONSTRAINED)
            }
          }
        }
      }
    }
  }
`
